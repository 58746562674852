import React from "react";
import Modal from "../utils/Modal";
import { PopupContext } from "./PopupContext";

export const Popup: React.FC = () => {
  const { popupState, setPopupState } = React.useContext(PopupContext);
  return (
    <Modal
      className=""
      visible={popupState.popped}
      handleClose={() => {
        console.log({ ...popupState, popped: false });
        setPopupState({ ...popupState, popped: false });
      }}
    >
      {popupState.poppedComponent}
    </Modal>
  );
};
