import React, { FC } from "react";

export interface PopupState {
  poppedComponent?: JSX.Element;
  popped: boolean;
}

export interface PopupContext {
  popupState: PopupState;
  setPopupState: (state: PopupState) => void;
}

let defaultPopupState: PopupState = {
  popped: false,
  poppedComponent: undefined,
};
let defaultContext: PopupContext = {
  popupState: defaultPopupState,
  setPopupState: () => {},
};

export const usePopup = (startState?: PopupState): PopupContext => {
  if (!startState) {
    startState = defaultPopupState;
  }
  const [popupState, setPopupState] = React.useState<PopupState>(startState);

  const setCurrentPopupState = React.useCallback(
    (currentPopupState: PopupState): void => {
      setPopupState(currentPopupState);
    },
    []
  );

  return {
    popupState: popupState,
    setPopupState: setCurrentPopupState,
  };
};

export const PopupContext = React.createContext(defaultContext);

export const PopupProvider: FC = ({ children }) => {
  const popupContext = usePopup();
  return (
    <PopupContext.Provider value={popupContext}>
      {children}
    </PopupContext.Provider>
  );
};

export const _ = "";
