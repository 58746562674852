import * as React from "react";
import { LatLngTuple, LatLng, LatLngBounds } from "leaflet";
import { Map, TileLayer, GeoJSON } from "react-leaflet";
// @ts-ignore
import FullscreenControl from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/dist/styles.css";
import bbox from "@turf/bbox";
import "./Table.css";
import "leaflet/dist/leaflet.css";

export interface RouteMapProps {
  geoJson: GeoJSON.GeoJsonObject;
  routeID: string;
}

export const RouteMap: React.FC<RouteMapProps> = (props) => {
  let onEachFeature = (feature: any, layer: any) => {
    const popupContent = ` <Popup><p>Customizable Popups <br />with feature information.</p><pre>Borough: <br />${feature.properties.name}</pre></Popup>`;
    layer.bindPopup(popupContent);
  };

  let getBounds = () => {
    if (props.routeID === "") {
      return undefined;
    }
    const bboxArray = bbox(props.geoJson);
    return new LatLngBounds(
      new LatLng(bboxArray[1], bboxArray[0]),
      new LatLng(bboxArray[3], bboxArray[2])
    );
  };

  const position: LatLngTuple = [56.04, 10.08];

  let GeoJSONLayer =
    props.routeID !== "" ? (
      <GeoJSON
        key={props.routeID}
        data={props.geoJson}
        //style={geoJSONStyle}
        onEachFeature={onEachFeature}
      />
    ) : null;

  const divStyle = {
    width: "100%",
    height: "200px",
    border: "1px solid black",
  };

  return (
    <div
      style={{
        height: "200px",
        width: "100%",
      }}
    >
      <Map
        key={props.routeID + "MK"}
        bounds={getBounds()}
        style={divStyle}
        center={position}
        zoom={14}
      >
        <TileLayer
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          // url='https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}'
          id="mapbox.streets"
          accessToken="pk.eyJ1IjoidGFnaG9mIiwiYSI6ImNqdXJjeThnajNta2s0YXFqODF1cXk4Y3oifQ.OWEWGOkWWSYUvzUVHrOdIg"
          maxZoom={18}
        />
        {GeoJSONLayer}
        <FullscreenControl position="topright" />
      </Map>
    </div>
  );
};
