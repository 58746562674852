import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import { useAuth } from "../../AuthContext";

const Footer: React.FC = () => {
  return (
    <div className="footer hidden-xs hidden-sm row marketing">
      <div className="no-padding-col col-lg-6 col-xs-6" style={{ zIndex: -1 }}>
        <p>&copy; 2021 Morten Daugaard</p>
      </div>
      <div className="no-padding-col col-lg-6 col-xs-6">
        <div className="float-right btn btn-pill" role="button">
          <LoginPopup />
        </div>
      </div>
    </div>
  );
};

interface LoginFormState {
  username?: string;
  password?: string;
  error?: string;
}

const LoginPopup: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [loginFormState, setLoginFormState] = React.useState<LoginFormState>({
    username: "",
    password: "",
  });

  const {
    authState: { state, authError },
    login,
    logout,
  } = useAuth();

  useEffect(() => {
    if (state === "expired") {
      setIsOpen(true);
    } else if (state === "authenticated") {
      setIsOpen(false);
    }
  }, [setIsOpen, state]);

  const logButton =
    state !== "authenticated" ? (
      <button
        id="loginSubmit"
        name="submit"
        type="submit"
        onClick={() => login(
          loginFormState.username, 
          loginFormState.password)} 
        className="btn btn-primary btn-lg btn-block"
      >
        Log ind
      </button>
    ) : (
      <button
        id="logoutSubmit"
        name="submit"
        type="submit"
        onClick={logout}
        className="btn btn-primary btn-lg btn-block"
      >
        Log ud
      </button>
    );

  return (
    <>
      <button
        onClick={() => (state === "authenticated" ? logout() : setIsOpen(true))}
      >
        {state === "authenticated" ? "Log ud" : "log ind"}
      </button>
      <Popup
        open={isOpen}
        closeOnDocumentClick
        onClose={() => setIsOpen(false)}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="close float-right"
                data-dismiss="modal"
                aria-label="Close"
              >
                <div
                  onClick={() => setIsOpen(false)}
                  className="material-icons"
                >
                  close
                </div>
              </button>
            </div>
            <div className="modal-body">
              <div id="div-login-msg">
                <div id="icon-login-msg" className="material-icons">
                  chevron_right
                </div>
                <span id="text-login-msg">Indtast brugernavn og password.</span>
              </div>
              <form>
                <input
                  id="username"
                  name="username"
                  className="form-control"
                  type="text"
                  placeholder="Brugernavn"
                  autoComplete="off"
                  onChange={(e) =>
                    setLoginFormState({
                      password: loginFormState?.password,
                      username: e.target.value,
                    })
                  }
                  value={loginFormState?.username}
                  required
                />
                <input
                  id="password"
                  name="password"
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  onChange={(e) =>
                    setLoginFormState({
                      password: e.target.value,
                      username: loginFormState?.username,
                    })
                  }
                  value={loginFormState?.password}
                  required
                />
              </form>
            </div>
            <div className="modal-footer">{logButton}{authError && <label>{authError}</label>}</div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default Footer;
