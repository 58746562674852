import * as React from "react";
import {RunningTable} from "./RunningTable";
import { RouteMap } from "./RouteMap";
import config from "../../config";
import { Tab } from 'semantic-ui-react'
import { RunningCards } from "./RunningCards";

interface RunProps {
    data:Workout[]
}

interface Workout {
    type: string,
    duration : string
}

export const Running : React.FC<RunProps> = (props: RunProps) => {
    let defaultSelection:GeoJSON.GeoJsonObject = {type : "Feature"};
    const [selectedRoute, setSelectedRoute] = React.useState<GeoJSON.GeoJsonObject>(defaultSelection);
    const [selectedRouteID, setSelectedRouteID] = React.useState<string>("");

    let setSelectedWorkoutAndFetchRoute = (id: string) => {
        if(id === ""){
            setSelectedRouteID(id);
            setSelectedRoute(defaultSelection);
        }
        fetch(config.graphql.url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ query: '{getRouteForWorkout(id : ' + id +')}' }),
        })
        .then(res => res.json())
        .then(res => {
            if(res.data && res.data.getRouteForWorkout){
                let parsed = JSON.parse(res.data.getRouteForWorkout.route); 
                setSelectedRoute(parsed);
                setSelectedRouteID(id);
            }
        }).catch(Error => console.log(Error));
    }
    const panes = [
        {
          menuItem: 'Nyeste',
          render: () => <Tab.Pane ><RunningCards /></Tab.Pane>,
        },
        { menuItem: 'Alle', render: () => <Tab.Pane>
            <RouteMap geoJson={selectedRoute} routeID={selectedRouteID}/>
            <RunningTable mapCallback={setSelectedWorkoutAndFetchRoute}/>
            </Tab.Pane> },
      ]

    return (
        <div className="Running">
            <Tab panes={panes} />            
            
        </div>
    );
} 