import gql from "graphql-tag";

export const LOGIN_QUERY = gql`
  query Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      firstName
      lastName
      token
      success
      login
      resultString
    }
  }
`;

export const REFRESH_TOKEN_QUERY = gql`
  query Refresh {
    refresh {
      firstName
      lastName
      token
      success
      login
      resultString
    }
  }
`;
export const GET_STATS = gql`
  query Stats {
    wishListStats {
      numberOfWishes
      lastUpdate
    }
    workoutStats {
      totalDistance
      totalDistanceRunning
      totalDistanceCycling
      totalTimeSpendString
      totalTimeSpendCyclingString
      totalTimeSpendRunningString
      lastRun {
        sport
        datetime
        distance
        duration
        avgSpeed
      }
    }
  }
`;

export const SYNC_LATEST_WORKOUTS = gql`
  query syncLatest {
    syncLatestWorkouts
  }
`;

export const GET_ALL_WORKOUTS = gql`
  query allWorkouts {
    getAllWorkouts {
      id
      externalId
      datetime
      duration
      calories
      elevationAsc
      elevationDesc
      goal
      maxHR
      avgHR
      distance
      altitudeMax
      altitudeMin
      maxSpeed
      avgSpeed
      hydration
      weather
      sport
    }
  }
`;

export const GET_LATEST_WORKOUTS = gql`
  query workouts($count: Int!) {
    getWorkouts(count: $count) {
      id
      externalId
      datetime
      duration
      calories
      elevationAsc
      elevationDesc
      goal
      maxHR
      avgHR
      distance
      altitudeMax
      altitudeMin
      maxSpeed
      avgSpeed
      hydration
      weather
      sport
    }
  }
`;

export const GET_ALL_WISHES = gql`
  query wishes {
    getAllWishes {
      id
      price
      name
      link
      description
      user {
        id
        realFirstName
        realLastName
        userName
      }
    }
  }
`;

export const ADD_WISH = gql`
  mutation AddWish($wish: WishInput!) {
    addWish(newWish: $wish) {
      link
      id
      state
      priority
      name
      price
      description
      user {
        id
        realFirstName
        realLastName
        userName
      }
      updated
      numericalPrice
    }
  }
`;

export const UPDATE_WISH = gql`
  mutation UpdateWish($wish: WishInput!) {
    editWish(wish: $wish)
  }
`;

export const SEND_WISHES = gql`
  query sendWishes($recipients: [String], $users: [String]) {
    sendWishes(recipients: $recipients, users: $users)
  }
`;

export const GET_RECIPIENTS = gql`
  query getRecipients {
    getRecipients
  }
`;

export const GET_WISHLIST_OWNERS = gql`
  query getWishListOwners {
    getOwners {
      realFirstName
      id
    }
  }
`;

export const GET_ALL_BLOGPOSTS = gql`
  query posts {
    getAllBlogPosts {
      id
      text
      published
      publishedDate
    }
  }
`;

export const GET_PHOTOS = gql`
  query photos {
    getPhotos {
      filename
      url
    }
  }
`;


export const ADD_BLOGPOST = gql`
  mutation AddBlogPost($blogPost: String!) {
    addBlogPost(newBlogPost: $blogPost) {
      id
    }
  }
`;

export const UPDATE_BLOGPOST = gql`
  mutation UpdateBlogPost($blogPost: BlogPostInput!) {
    editBlogPost(blogPost: $blogPost)
  }
`;

export const DELETE_BLOGPOST = gql`
  mutation deleteBlogPost($blogPostId: Int!) {
    deleteBlogPost(blogPostId: $blogPostId)
  }
`;
