export const removeProperty: (obj:any, propertyName:string) => any = (obj, propertyName) => {
  let {  [propertyName]: _, ...result } = obj;
    return result;
  };

export const removeProperties: (obj:any, propertyNames:string[]) => any = (obj, propertyNames) => {
  let resObj = obj;
  propertyNames.forEach(element => {
    resObj = removeProperty(resObj, element);
  });
  return resObj;
};
