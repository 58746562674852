import { ApolloLink, concat, HttpLink } from "apollo-boost";
// import { TokenRefreshLink } from "apollo-link-token-refresh";
// import jwtDecode, { JwtPayload } from "jwt-decode";

import { InMemoryCache, NormalizedCacheObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import React, { FC, useContext, useEffect } from "react";
import { ApolloProvider as Provider } from "react-apollo";
import { AuthContext } from "./AuthContext";
import config from "./config";
import { RefreshQuery } from "./graphql/GraphqlTypes";
import { REFRESH_TOKEN_QUERY } from "./graphql/GraphqlQueries";

const cache = new InMemoryCache();

export const ApolloProvider: FC = ({ children }) => {
  const {
    authState: { authToken },
    authDispatch,
  } = useContext(AuthContext);

  const link = new HttpLink({
    uri: config.graphql.url,
    credentials: config.graphql.credentials,
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Origin: "https://taghof.dk",
        Authorization: authToken ? `Bearer ${authToken}` : "",
      },
    }));

    return forward(operation);
  });

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    cache: cache,
    link: concat(authMiddleware, link),
  });

  useEffect(() => {
    window.addEventListener("storage", () => {
      const state = localStorage.getItem("authState");
      switch (state) {
        case "expired":
          authDispatch({
            type: "refreshFailure",
            error: "expired",
            askForReauth: true,
          });
          break;
        case "unAuthenticated":
          authDispatch({ type: "logoutSuccess" });
          break;
      }
    });

    if (doesHttpOnlyCookieExist("refreshToken")) {
      console.log("trying to do init refresh");
      client
        .query<RefreshQuery>({
          query: REFRESH_TOKEN_QUERY,
          fetchPolicy: "network-only",
        })
        .then((response) => {
          if (response.data.refresh?.success) {
            authDispatch({
              type: "loginSuccess",
              result: response.data.refresh,
            });
          } else {
            console.log(response.errors?.map((e) => e.message).join(" | "));
            authDispatch({
              type: "refreshFailure",
              error:
                response.errors?.map((e) => e.message).join(" | ") ??
                "Not able to refresh token",
              askForReauth: false,
            });
          }
        })
        .catch((error) => {
          console.log("more failure");
          authDispatch({ type: "refreshFailure", error, askForReauth: false });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Provider client={client}>{children}</Provider>;
};

function doesHttpOnlyCookieExist(cookiename: string) {
  const d = new Date();
  d.setTime(d.getTime() + 1000);
  var expires = "expires=" + d.toUTCString();

  document.cookie = cookiename + "=new_value;path=/;" + expires;
  return document.cookie.indexOf(cookiename + "=") === -1;
}
