import React from "react";

import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
} from "react-router-dom";
import MenuHeader from "./components/menuheader/MenuHeader";
import HomePage from "./components/home/Home";
import Wishlists from "./components/wishlist/WishLists";
import { Running } from "./components/running/Running";
import WishlistDistributer from "./components/wishlist/WishlistDistributer";
import { AddBlogPostComponent } from "./components/blog/BlogPostComponent";
import Blog from "./components/blog/Blog";

import { Popup } from "./components/popup/Popup";
import Footer from "./components/footer/Footer";
import { useAuth } from "./AuthContext";

type RCP<A> = RouteComponentProps<A, any, any>;

export interface SiteEntryParams {
  id: string;
  title: string;
  access: "restricted" | "public";
}

export interface SiteGroup extends SiteEntryParams {
  type: "group";
  children?: SiteEntry[];
}

export interface SitePage extends SiteEntryParams {
  type: "page";
  path: string;
  pathTemplate?: string;
  renderMethod: (props?: RCP<BlogParams> | RCP<any>) => JSX.Element;
}

export interface SiteActionProps {
  onDone: () => void;
  onStart?: () => void;
}

export interface SiteAction extends SiteEntryParams {
  type: "action";
  renderMethod: (props: SiteActionProps) => JSX.Element;
}

export type SiteEntry = SitePage | SiteAction | SiteGroup;

export function isPage(arg?: SiteEntry): arg is SitePage {
  return !!arg && arg.type === "page";
}

export function isGroup(arg?: SiteEntry): arg is SiteGroup {
  return !!arg && arg.type === "group";
}

export function isAction(arg?: SiteEntry): arg is SiteAction {
  return !!arg && arg.type === "action";
}

export interface BlogParams {
  state: string;
}

export const Site: React.FC<{}> = () => {
  const { authState } = useAuth();

  const config: SiteEntry[] = [
    {
      id: "0",
      title: "Home",
      path: "/",
      access: "public",
      renderMethod: () => <HomePage />,
      type: "page",
    },
    {
      id: "1",
      title: "Ønskesedler",
      path: "/wishlist",
      access: "public",
      renderMethod: () => <Wishlists />,
      type: "page",
    },
    {
      id: "2",
      title: "Løb",
      path: "/running",
      access: "public",
      renderMethod: () => <Running data={[]} />,
      type: "page",
    },
    {
      id: "3",
      title: "Blog",
      path: "/blog",
      access: "public",
      renderMethod: (p?: RCP<BlogParams>) => <Blog />,
      type: "page",
    },
    {
      id: "4",
      title: "Administration",
      access: "restricted",
      type: "group",
      children: [
        {
          id: "4.1",
          title: "Tilføj blog post",
          type: "action",
          access: "restricted",
          renderMethod: (d) => <AddBlogPostComponent onDone={d.onDone} />,
        } as SiteAction,
        {
          id: "4.2",
          title: "Bruger administration",
          type: "page",
          path: "/useradm",
          access: "restricted",
          renderMethod: () => <HomePage />,
        },
        {
          id: "4.3",
          title: "Send Ønskeseddel",
          type: "action",
          access: "restricted",
          renderMethod: (d) => <WishlistDistributer onDone={d.onDone} />,
        } as SiteAction,
      ],
    },
  ];

  const generateRoutes: (entry: SiteEntry) => (JSX.Element | undefined)[] = (
    entry: SiteEntry
  ) => {
    if (isPage(entry)) {
      return [
        <Route
          key={entry.id}
          path={entry.path}
          exact
          render={entry.renderMethod}
        />,
      ];
    } else if (isGroup(entry)) {
      return entry.children?.map((e) => generateRoutes(e)).flat() || [];
    }
    return [];
  };

  const filteredConfig: SiteEntry[] = config.filter(
    (page) =>
      !(page.access === "restricted" && authState.state !== "authenticated")
  );

  return (
    <div className="container">
      <Router>
        <Popup />
        <MenuHeader title="Taghof.dk" config={filteredConfig} />
        {filteredConfig.map(generateRoutes)}
      </Router>
      <Footer />
    </div>
  );
};
