import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Built-in scalar for map-like structures */
  Map_String_ObjectScalar: any;
  /** Built-in scalar representing an instant in time */
  Date: any;
  /** Long type */
  Long: any;
  /** Built-in scalar for map-like structures */
  Map_String_List_WishScalar: any;
  /** Built-in java.math.BigDecimal */
  BigDecimal: any;
  /** Built-in scalar for map-like structures */
  Map_String_StringScalar: any;
  /** Use SPQR's SchemaPrinter to remove this from SDL */
  UNREPRESENTABLE: any;
};


export type AuthReply = {
  __typename?: 'AuthReply';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  resultString?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};


export type BlogPost = {
  __typename?: 'BlogPost';
  author?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  published: Scalars['Boolean'];
  publishedDate?: Maybe<Scalars['Date']>;
  text?: Maybe<Scalars['String']>;
};

export type BlogPostInput = {
  createddate?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  published: Scalars['Boolean'];
  publisheddate?: Maybe<Scalars['Date']>;
  text?: Maybe<Scalars['String']>;
};






/** Mutation root */
export type Mutation = {
  __typename?: 'Mutation';
  addWish?: Maybe<Wish>;
  editBlogPost: Scalars['Boolean'];
  hideWish: Scalars['Boolean'];
  deleteBlogPost: Scalars['Boolean'];
  addBlogPost?: Maybe<BlogPost>;
  editWish: Scalars['Boolean'];
};


/** Mutation root */
export type MutationAddWishArgs = {
  newWish?: Maybe<WishInput>;
};


/** Mutation root */
export type MutationEditBlogPostArgs = {
  blogPost?: Maybe<BlogPostInput>;
};


/** Mutation root */
export type MutationHideWishArgs = {
  id: Scalars['Int'];
};


/** Mutation root */
export type MutationDeleteBlogPostArgs = {
  blogPostId: Scalars['Int'];
};


/** Mutation root */
export type MutationAddBlogPostArgs = {
  newBlogPost?: Maybe<Scalars['String']>;
};


/** Mutation root */
export type MutationEditWishArgs = {
  wish?: Maybe<WishInput>;
};

/** Query root */
export type Query = {
  __typename?: 'Query';
  getRouteForWorkout?: Maybe<Scalars['Map_String_ObjectScalar']>;
  workoutStats?: Maybe<WorkoutStats>;
  getAllBlogPosts?: Maybe<Array<Maybe<BlogPost>>>;
  refresh?: Maybe<AuthReply>;
  login?: Maybe<AuthReply>;
  getWishes?: Maybe<Scalars['Map_String_List_WishScalar']>;
  getAllWishes?: Maybe<Array<Maybe<Wish>>>;
  wishListStats?: Maybe<WishListStats>;
  howWasIt?: Maybe<Scalars['String']>;
  getAllWorkouts?: Maybe<Array<Maybe<Workout>>>;
  syncLatestWorkouts?: Maybe<Scalars['Boolean']>;
  getWorkouts?: Maybe<Array<Maybe<Workout>>>;
  getOwners?: Maybe<Array<Maybe<User>>>;
  syncAllWorkouts?: Maybe<Scalars['Boolean']>;
  sendWishes: Scalars['Boolean'];
  getRecipients?: Maybe<Scalars['Map_String_StringScalar']>;
};


/** Query root */
export type QueryGetRouteForWorkoutArgs = {
  id: Scalars['Int'];
};


/** Query root */
export type QueryLoginArgs = {
  password?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};


/** Query root */
export type QueryGetWishesArgs = {
  users?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Query root */
export type QueryHowWasItArgs = {
  Workout?: Maybe<WorkoutInput>;
};


/** Query root */
export type QueryGetWorkoutsArgs = {
  count: Scalars['Int'];
};


/** Query root */
export type QuerySendWishesArgs = {
  recipients?: Maybe<Array<Maybe<Scalars['String']>>>;
  users?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Role = {
  __typename?: 'Role';
  authority?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type RoleInput = {
  authority?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type Route = {
  __typename?: 'Route';
  id?: Maybe<Scalars['Long']>;
  points?: Maybe<Scalars['String']>;
  workout?: Maybe<Workout>;
};

export type RouteInput = {
  id?: Maybe<Scalars['Long']>;
  points?: Maybe<Scalars['String']>;
};

export enum SportType {
  Any = 'ANY',
  Cycling = 'CYCLING',
  Other = 'OTHER',
  Running = 'RUNNING',
  Strength = 'STRENGTH',
  Walking = 'WALKING'
}


export type User = {
  __typename?: 'User';
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  password?: Maybe<Scalars['String']>;
  realFirstName?: Maybe<Scalars['String']>;
  realLastName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  userName?: Maybe<Scalars['String']>;
};

export type UserInput = {
  enabled: Scalars['Boolean'];
  id: Scalars['Int'];
  password?: Maybe<Scalars['String']>;
  realFirstName?: Maybe<Scalars['String']>;
  realLastName?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<RoleInput>>>;
  userName?: Maybe<Scalars['String']>;
};

export type Wish = {
  __typename?: 'Wish';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numericalPrice?: Maybe<Scalars['BigDecimal']>;
  price?: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  shortLink?: Maybe<Scalars['String']>;
  state?: Maybe<WishState>;
  updated?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export type WishInput = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numericalPrice?: Maybe<Scalars['BigDecimal']>;
  price?: Maybe<Scalars['String']>;
  priority: Scalars['Int'];
  state?: Maybe<WishState>;
  updated?: Maybe<Scalars['Date']>;
  user?: Maybe<UserInput>;
};

export type WishListStats = {
  __typename?: 'WishListStats';
  lastUpdate?: Maybe<Scalars['Date']>;
  numberOfWishes: Scalars['Int'];
};

export enum WishState {
  Fulfilled = 'FULFILLED',
  Hidden = 'HIDDEN',
  Pending = 'PENDING'
}

export type Workout = {
  __typename?: 'Workout';
  altitudeMax: Scalars['Float'];
  altitudeMin: Scalars['Float'];
  avgHR: Scalars['Int'];
  avgSpeed: Scalars['Float'];
  calories: Scalars['Float'];
  datetime?: Maybe<Scalars['Date']>;
  distance: Scalars['Float'];
  duration: Scalars['Int'];
  elevationAsc: Scalars['Float'];
  elevationDesc: Scalars['Float'];
  externalId?: Maybe<Scalars['Long']>;
  goal?: Maybe<Scalars['String']>;
  hydration: Scalars['Float'];
  id?: Maybe<Scalars['Long']>;
  maxHR: Scalars['Int'];
  maxSpeed: Scalars['Float'];
  route?: Maybe<Array<Maybe<Route>>>;
  sport?: Maybe<SportType>;
  weather?: Maybe<Scalars['String']>;
};

export type WorkoutInput = {
  altitude_max: Scalars['Float'];
  altitude_min: Scalars['Float'];
  ascent: Scalars['Float'];
  calories: Scalars['Float'];
  descent: Scalars['Float'];
  distance: Scalars['Float'];
  duration: Scalars['Int'];
  goal?: Maybe<Scalars['String']>;
  heart_rate_avg: Scalars['Int'];
  heart_rate_max: Scalars['Int'];
  hydration: Scalars['Float'];
  id?: Maybe<Scalars['Long']>;
  id_internal?: Maybe<Scalars['Long']>;
  points?: Maybe<Array<Maybe<RouteInput>>>;
  speed_avg: Scalars['Float'];
  speed_max: Scalars['Float'];
  sport?: Maybe<SportType>;
  start_time?: Maybe<Scalars['Date']>;
  weather?: Maybe<Scalars['String']>;
};

export type WorkoutStats = {
  __typename?: 'WorkoutStats';
  hoursSinceLastWorkoutString?: Maybe<Scalars['String']>;
  lastCycling?: Maybe<Workout>;
  lastRun?: Maybe<Workout>;
  lastWorkout?: Maybe<Workout>;
  lastWorkoutDurationString?: Maybe<Scalars['String']>;
  numberOfCyclings: Scalars['Int'];
  numberOfRuns: Scalars['Int'];
  numberOfWorkouts: Scalars['Int'];
  totalDistance: Scalars['Float'];
  totalDistanceCycling: Scalars['Float'];
  totalDistanceRunning: Scalars['Float'];
  totalTimeSpend: Scalars['Float'];
  totalTimeSpendCycling: Scalars['Float'];
  totalTimeSpendCyclingString?: Maybe<Scalars['String']>;
  totalTimeSpendRunning: Scalars['Float'];
  totalTimeSpendRunningString?: Maybe<Scalars['String']>;
  totalTimeSpendString?: Maybe<Scalars['String']>;
};

export type LoginQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginQuery = (
  { __typename?: 'Query' }
  & { login?: Maybe<(
    { __typename?: 'AuthReply' }
    & Pick<AuthReply, 'firstName' | 'lastName' | 'token' | 'success' | 'login' | 'resultString'>
  )> }
);

export type RefreshQueryVariables = Exact<{ [key: string]: never; }>;


export type RefreshQuery = (
  { __typename?: 'Query' }
  & { refresh?: Maybe<(
    { __typename?: 'AuthReply' }
    & Pick<AuthReply, 'firstName' | 'lastName' | 'token' | 'success' | 'login' | 'resultString'>
  )> }
);

export type StatsQueryVariables = Exact<{ [key: string]: never; }>;


export type StatsQuery = (
  { __typename?: 'Query' }
  & { wishListStats?: Maybe<(
    { __typename?: 'WishListStats' }
    & Pick<WishListStats, 'numberOfWishes' | 'lastUpdate'>
  )>, workoutStats?: Maybe<(
    { __typename?: 'WorkoutStats' }
    & Pick<WorkoutStats, 'totalDistance' | 'totalDistanceRunning' | 'totalDistanceCycling' | 'totalTimeSpendString' | 'totalTimeSpendCyclingString' | 'totalTimeSpendRunningString'>
    & { lastRun?: Maybe<(
      { __typename?: 'Workout' }
      & Pick<Workout, 'sport' | 'datetime' | 'distance' | 'duration' | 'avgSpeed'>
    )> }
  )> }
);

export type SyncLatestQueryVariables = Exact<{ [key: string]: never; }>;


export type SyncLatestQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'syncLatestWorkouts'>
);

export type AllWorkoutsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllWorkoutsQuery = (
  { __typename?: 'Query' }
  & { getAllWorkouts?: Maybe<Array<Maybe<(
    { __typename?: 'Workout' }
    & Pick<Workout, 'id' | 'externalId' | 'datetime' | 'duration' | 'calories' | 'elevationAsc' | 'elevationDesc' | 'goal' | 'maxHR' | 'avgHR' | 'distance' | 'altitudeMax' | 'altitudeMin' | 'maxSpeed' | 'avgSpeed' | 'hydration' | 'weather' | 'sport'>
  )>>> }
);

export type WorkoutsQueryVariables = Exact<{
  count: Scalars['Int'];
}>;


export type WorkoutsQuery = (
  { __typename?: 'Query' }
  & { getWorkouts?: Maybe<Array<Maybe<(
    { __typename?: 'Workout' }
    & Pick<Workout, 'id' | 'externalId' | 'datetime' | 'duration' | 'calories' | 'elevationAsc' | 'elevationDesc' | 'goal' | 'maxHR' | 'avgHR' | 'distance' | 'altitudeMax' | 'altitudeMin' | 'maxSpeed' | 'avgSpeed' | 'hydration' | 'weather' | 'sport'>
  )>>> }
);

export type WishesQueryVariables = Exact<{ [key: string]: never; }>;


export type WishesQuery = (
  { __typename?: 'Query' }
  & { getAllWishes?: Maybe<Array<Maybe<(
    { __typename?: 'Wish' }
    & Pick<Wish, 'id' | 'price' | 'name' | 'link' | 'description'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'realFirstName' | 'realLastName' | 'userName'>
    )> }
  )>>> }
);

export type AddWishMutationVariables = Exact<{
  wish: WishInput;
}>;


export type AddWishMutation = (
  { __typename?: 'Mutation' }
  & { addWish?: Maybe<(
    { __typename?: 'Wish' }
    & Pick<Wish, 'link' | 'id' | 'state' | 'priority' | 'name' | 'price' | 'description' | 'updated' | 'numericalPrice'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'realFirstName' | 'realLastName' | 'userName'>
    )> }
  )> }
);

export type UpdateWishMutationVariables = Exact<{
  wish: WishInput;
}>;


export type UpdateWishMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editWish'>
);

export type SendWishesQueryVariables = Exact<{
  recipients?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  users?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type SendWishesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sendWishes'>
);

export type GetRecipientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRecipientsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRecipients'>
);

export type GetWishListOwnersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWishListOwnersQuery = (
  { __typename?: 'Query' }
  & { getOwners?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'realFirstName' | 'id'>
  )>>> }
);

export type PostsQueryVariables = Exact<{ [key: string]: never; }>;


export type PostsQuery = (
  { __typename?: 'Query' }
  & { getAllBlogPosts?: Maybe<Array<Maybe<(
    { __typename?: 'BlogPost' }
    & Pick<BlogPost, 'id' | 'text' | 'published' | 'publishedDate'>
  )>>> }
);

export type AddBlogPostMutationVariables = Exact<{
  blogPost: Scalars['String'];
}>;


export type AddBlogPostMutation = (
  { __typename?: 'Mutation' }
  & { addBlogPost?: Maybe<(
    { __typename?: 'BlogPost' }
    & Pick<BlogPost, 'id'>
  )> }
);

export type UpdateBlogPostMutationVariables = Exact<{
  blogPost: BlogPostInput;
}>;


export type UpdateBlogPostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'editBlogPost'>
);

export type DeleteBlogPostMutationVariables = Exact<{
  blogPostId: Scalars['Int'];
}>;


export type DeleteBlogPostMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBlogPost'>
);


export const LoginDocument = gql`
    query Login($username: String!, $password: String!) {
  login(username: $username, password: $password) {
    firstName
    lastName
    token
    success
    login
    resultString
  }
}
    `;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        return ApolloReactHooks.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, baseOptions);
      }
export function useLoginLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, baseOptions);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = ApolloReactCommon.QueryResult<LoginQuery, LoginQueryVariables>;
export const RefreshDocument = gql`
    query Refresh {
  refresh {
    firstName
    lastName
    token
    success
    login
    resultString
  }
}
    `;

/**
 * __useRefreshQuery__
 *
 * To run a query within a React component, call `useRefreshQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshQuery({
 *   variables: {
 *   },
 * });
 */
export function useRefreshQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RefreshQuery, RefreshQueryVariables>) {
        return ApolloReactHooks.useQuery<RefreshQuery, RefreshQueryVariables>(RefreshDocument, baseOptions);
      }
export function useRefreshLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RefreshQuery, RefreshQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RefreshQuery, RefreshQueryVariables>(RefreshDocument, baseOptions);
        }
export type RefreshQueryHookResult = ReturnType<typeof useRefreshQuery>;
export type RefreshLazyQueryHookResult = ReturnType<typeof useRefreshLazyQuery>;
export type RefreshQueryResult = ApolloReactCommon.QueryResult<RefreshQuery, RefreshQueryVariables>;
export const StatsDocument = gql`
    query Stats {
  wishListStats {
    numberOfWishes
    lastUpdate
  }
  workoutStats {
    totalDistance
    totalDistanceRunning
    totalDistanceCycling
    totalTimeSpendString
    totalTimeSpendCyclingString
    totalTimeSpendRunningString
    lastRun {
      sport
      datetime
      distance
      duration
      avgSpeed
    }
  }
}
    `;

/**
 * __useStatsQuery__
 *
 * To run a query within a React component, call `useStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StatsQuery, StatsQueryVariables>) {
        return ApolloReactHooks.useQuery<StatsQuery, StatsQueryVariables>(StatsDocument, baseOptions);
      }
export function useStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StatsQuery, StatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StatsQuery, StatsQueryVariables>(StatsDocument, baseOptions);
        }
export type StatsQueryHookResult = ReturnType<typeof useStatsQuery>;
export type StatsLazyQueryHookResult = ReturnType<typeof useStatsLazyQuery>;
export type StatsQueryResult = ApolloReactCommon.QueryResult<StatsQuery, StatsQueryVariables>;
export const SyncLatestDocument = gql`
    query syncLatest {
  syncLatestWorkouts
}
    `;

/**
 * __useSyncLatestQuery__
 *
 * To run a query within a React component, call `useSyncLatestQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncLatestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncLatestQuery({
 *   variables: {
 *   },
 * });
 */
export function useSyncLatestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SyncLatestQuery, SyncLatestQueryVariables>) {
        return ApolloReactHooks.useQuery<SyncLatestQuery, SyncLatestQueryVariables>(SyncLatestDocument, baseOptions);
      }
export function useSyncLatestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SyncLatestQuery, SyncLatestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SyncLatestQuery, SyncLatestQueryVariables>(SyncLatestDocument, baseOptions);
        }
export type SyncLatestQueryHookResult = ReturnType<typeof useSyncLatestQuery>;
export type SyncLatestLazyQueryHookResult = ReturnType<typeof useSyncLatestLazyQuery>;
export type SyncLatestQueryResult = ApolloReactCommon.QueryResult<SyncLatestQuery, SyncLatestQueryVariables>;
export const AllWorkoutsDocument = gql`
    query allWorkouts {
  getAllWorkouts {
    id
    externalId
    datetime
    duration
    calories
    elevationAsc
    elevationDesc
    goal
    maxHR
    avgHR
    distance
    altitudeMax
    altitudeMin
    maxSpeed
    avgSpeed
    hydration
    weather
    sport
  }
}
    `;

/**
 * __useAllWorkoutsQuery__
 *
 * To run a query within a React component, call `useAllWorkoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWorkoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWorkoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllWorkoutsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllWorkoutsQuery, AllWorkoutsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllWorkoutsQuery, AllWorkoutsQueryVariables>(AllWorkoutsDocument, baseOptions);
      }
export function useAllWorkoutsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllWorkoutsQuery, AllWorkoutsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllWorkoutsQuery, AllWorkoutsQueryVariables>(AllWorkoutsDocument, baseOptions);
        }
export type AllWorkoutsQueryHookResult = ReturnType<typeof useAllWorkoutsQuery>;
export type AllWorkoutsLazyQueryHookResult = ReturnType<typeof useAllWorkoutsLazyQuery>;
export type AllWorkoutsQueryResult = ApolloReactCommon.QueryResult<AllWorkoutsQuery, AllWorkoutsQueryVariables>;
export const WorkoutsDocument = gql`
    query workouts($count: Int!) {
  getWorkouts(count: $count) {
    id
    externalId
    datetime
    duration
    calories
    elevationAsc
    elevationDesc
    goal
    maxHR
    avgHR
    distance
    altitudeMax
    altitudeMin
    maxSpeed
    avgSpeed
    hydration
    weather
    sport
  }
}
    `;

/**
 * __useWorkoutsQuery__
 *
 * To run a query within a React component, call `useWorkoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkoutsQuery({
 *   variables: {
 *      count: // value for 'count'
 *   },
 * });
 */
export function useWorkoutsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WorkoutsQuery, WorkoutsQueryVariables>) {
        return ApolloReactHooks.useQuery<WorkoutsQuery, WorkoutsQueryVariables>(WorkoutsDocument, baseOptions);
      }
export function useWorkoutsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WorkoutsQuery, WorkoutsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WorkoutsQuery, WorkoutsQueryVariables>(WorkoutsDocument, baseOptions);
        }
export type WorkoutsQueryHookResult = ReturnType<typeof useWorkoutsQuery>;
export type WorkoutsLazyQueryHookResult = ReturnType<typeof useWorkoutsLazyQuery>;
export type WorkoutsQueryResult = ApolloReactCommon.QueryResult<WorkoutsQuery, WorkoutsQueryVariables>;
export const WishesDocument = gql`
    query wishes {
  getAllWishes {
    id
    price
    name
    link
    description
    user {
      id
      realFirstName
      realLastName
      userName
    }
  }
}
    `;

/**
 * __useWishesQuery__
 *
 * To run a query within a React component, call `useWishesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWishesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWishesQuery({
 *   variables: {
 *   },
 * });
 */
export function useWishesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WishesQuery, WishesQueryVariables>) {
        return ApolloReactHooks.useQuery<WishesQuery, WishesQueryVariables>(WishesDocument, baseOptions);
      }
export function useWishesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WishesQuery, WishesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WishesQuery, WishesQueryVariables>(WishesDocument, baseOptions);
        }
export type WishesQueryHookResult = ReturnType<typeof useWishesQuery>;
export type WishesLazyQueryHookResult = ReturnType<typeof useWishesLazyQuery>;
export type WishesQueryResult = ApolloReactCommon.QueryResult<WishesQuery, WishesQueryVariables>;
export const AddWishDocument = gql`
    mutation AddWish($wish: WishInput!) {
  addWish(newWish: $wish) {
    link
    id
    state
    priority
    name
    price
    description
    user {
      id
      realFirstName
      realLastName
      userName
    }
    updated
    numericalPrice
  }
}
    `;
export type AddWishMutationFn = ApolloReactCommon.MutationFunction<AddWishMutation, AddWishMutationVariables>;

/**
 * __useAddWishMutation__
 *
 * To run a mutation, you first call `useAddWishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWishMutation, { data, loading, error }] = useAddWishMutation({
 *   variables: {
 *      wish: // value for 'wish'
 *   },
 * });
 */
export function useAddWishMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddWishMutation, AddWishMutationVariables>) {
        return ApolloReactHooks.useMutation<AddWishMutation, AddWishMutationVariables>(AddWishDocument, baseOptions);
      }
export type AddWishMutationHookResult = ReturnType<typeof useAddWishMutation>;
export type AddWishMutationResult = ApolloReactCommon.MutationResult<AddWishMutation>;
export type AddWishMutationOptions = ApolloReactCommon.BaseMutationOptions<AddWishMutation, AddWishMutationVariables>;
export const UpdateWishDocument = gql`
    mutation UpdateWish($wish: WishInput!) {
  editWish(wish: $wish)
}
    `;
export type UpdateWishMutationFn = ApolloReactCommon.MutationFunction<UpdateWishMutation, UpdateWishMutationVariables>;

/**
 * __useUpdateWishMutation__
 *
 * To run a mutation, you first call `useUpdateWishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWishMutation, { data, loading, error }] = useUpdateWishMutation({
 *   variables: {
 *      wish: // value for 'wish'
 *   },
 * });
 */
export function useUpdateWishMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateWishMutation, UpdateWishMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateWishMutation, UpdateWishMutationVariables>(UpdateWishDocument, baseOptions);
      }
export type UpdateWishMutationHookResult = ReturnType<typeof useUpdateWishMutation>;
export type UpdateWishMutationResult = ApolloReactCommon.MutationResult<UpdateWishMutation>;
export type UpdateWishMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateWishMutation, UpdateWishMutationVariables>;
export const SendWishesDocument = gql`
    query sendWishes($recipients: [String], $users: [String]) {
  sendWishes(recipients: $recipients, users: $users)
}
    `;

/**
 * __useSendWishesQuery__
 *
 * To run a query within a React component, call `useSendWishesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendWishesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendWishesQuery({
 *   variables: {
 *      recipients: // value for 'recipients'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useSendWishesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SendWishesQuery, SendWishesQueryVariables>) {
        return ApolloReactHooks.useQuery<SendWishesQuery, SendWishesQueryVariables>(SendWishesDocument, baseOptions);
      }
export function useSendWishesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SendWishesQuery, SendWishesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SendWishesQuery, SendWishesQueryVariables>(SendWishesDocument, baseOptions);
        }
export type SendWishesQueryHookResult = ReturnType<typeof useSendWishesQuery>;
export type SendWishesLazyQueryHookResult = ReturnType<typeof useSendWishesLazyQuery>;
export type SendWishesQueryResult = ApolloReactCommon.QueryResult<SendWishesQuery, SendWishesQueryVariables>;
export const GetRecipientsDocument = gql`
    query getRecipients {
  getRecipients
}
    `;

/**
 * __useGetRecipientsQuery__
 *
 * To run a query within a React component, call `useGetRecipientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecipientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecipientsQuery, GetRecipientsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRecipientsQuery, GetRecipientsQueryVariables>(GetRecipientsDocument, baseOptions);
      }
export function useGetRecipientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecipientsQuery, GetRecipientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRecipientsQuery, GetRecipientsQueryVariables>(GetRecipientsDocument, baseOptions);
        }
export type GetRecipientsQueryHookResult = ReturnType<typeof useGetRecipientsQuery>;
export type GetRecipientsLazyQueryHookResult = ReturnType<typeof useGetRecipientsLazyQuery>;
export type GetRecipientsQueryResult = ApolloReactCommon.QueryResult<GetRecipientsQuery, GetRecipientsQueryVariables>;
export const GetWishListOwnersDocument = gql`
    query getWishListOwners {
  getOwners {
    realFirstName
    id
  }
}
    `;

/**
 * __useGetWishListOwnersQuery__
 *
 * To run a query within a React component, call `useGetWishListOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWishListOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWishListOwnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWishListOwnersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWishListOwnersQuery, GetWishListOwnersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWishListOwnersQuery, GetWishListOwnersQueryVariables>(GetWishListOwnersDocument, baseOptions);
      }
export function useGetWishListOwnersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWishListOwnersQuery, GetWishListOwnersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWishListOwnersQuery, GetWishListOwnersQueryVariables>(GetWishListOwnersDocument, baseOptions);
        }
export type GetWishListOwnersQueryHookResult = ReturnType<typeof useGetWishListOwnersQuery>;
export type GetWishListOwnersLazyQueryHookResult = ReturnType<typeof useGetWishListOwnersLazyQuery>;
export type GetWishListOwnersQueryResult = ApolloReactCommon.QueryResult<GetWishListOwnersQuery, GetWishListOwnersQueryVariables>;
export const PostsDocument = gql`
    query posts {
  getAllBlogPosts {
    id
    text
    published
    publishedDate
  }
}
    `;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
        return ApolloReactHooks.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
      }
export function usePostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, baseOptions);
        }
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = ApolloReactCommon.QueryResult<PostsQuery, PostsQueryVariables>;
export const AddBlogPostDocument = gql`
    mutation AddBlogPost($blogPost: String!) {
  addBlogPost(newBlogPost: $blogPost) {
    id
  }
}
    `;
export type AddBlogPostMutationFn = ApolloReactCommon.MutationFunction<AddBlogPostMutation, AddBlogPostMutationVariables>;

/**
 * __useAddBlogPostMutation__
 *
 * To run a mutation, you first call `useAddBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBlogPostMutation, { data, loading, error }] = useAddBlogPostMutation({
 *   variables: {
 *      blogPost: // value for 'blogPost'
 *   },
 * });
 */
export function useAddBlogPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddBlogPostMutation, AddBlogPostMutationVariables>) {
        return ApolloReactHooks.useMutation<AddBlogPostMutation, AddBlogPostMutationVariables>(AddBlogPostDocument, baseOptions);
      }
export type AddBlogPostMutationHookResult = ReturnType<typeof useAddBlogPostMutation>;
export type AddBlogPostMutationResult = ApolloReactCommon.MutationResult<AddBlogPostMutation>;
export type AddBlogPostMutationOptions = ApolloReactCommon.BaseMutationOptions<AddBlogPostMutation, AddBlogPostMutationVariables>;
export const UpdateBlogPostDocument = gql`
    mutation UpdateBlogPost($blogPost: BlogPostInput!) {
  editBlogPost(blogPost: $blogPost)
}
    `;
export type UpdateBlogPostMutationFn = ApolloReactCommon.MutationFunction<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>;

/**
 * __useUpdateBlogPostMutation__
 *
 * To run a mutation, you first call `useUpdateBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlogPostMutation, { data, loading, error }] = useUpdateBlogPostMutation({
 *   variables: {
 *      blogPost: // value for 'blogPost'
 *   },
 * });
 */
export function useUpdateBlogPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>(UpdateBlogPostDocument, baseOptions);
      }
export type UpdateBlogPostMutationHookResult = ReturnType<typeof useUpdateBlogPostMutation>;
export type UpdateBlogPostMutationResult = ApolloReactCommon.MutationResult<UpdateBlogPostMutation>;
export type UpdateBlogPostMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBlogPostMutation, UpdateBlogPostMutationVariables>;
export const DeleteBlogPostDocument = gql`
    mutation deleteBlogPost($blogPostId: Int!) {
  deleteBlogPost(blogPostId: $blogPostId)
}
    `;
export type DeleteBlogPostMutationFn = ApolloReactCommon.MutationFunction<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>;

/**
 * __useDeleteBlogPostMutation__
 *
 * To run a mutation, you first call `useDeleteBlogPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlogPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlogPostMutation, { data, loading, error }] = useDeleteBlogPostMutation({
 *   variables: {
 *      blogPostId: // value for 'blogPostId'
 *   },
 * });
 */
export function useDeleteBlogPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>(DeleteBlogPostDocument, baseOptions);
      }
export type DeleteBlogPostMutationHookResult = ReturnType<typeof useDeleteBlogPostMutation>;
export type DeleteBlogPostMutationResult = ApolloReactCommon.MutationResult<DeleteBlogPostMutation>;
export type DeleteBlogPostMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBlogPostMutation, DeleteBlogPostMutationVariables>;