import * as React from "react";
import {NavLink, Link, withRouter, RouteComponentProps} from "react-router-dom";
import { SiteEntry, isPage, isGroup, SiteActionProps } from "../../Site";

interface MHProps { title: string, config : SiteEntry[]};
interface MIProp  { id : string, name: string, path : string, onSelect: (activeItem : string) => void };
interface MProps  { title : string; }

export const MenuHeader: React.FC<MHProps & RouteComponentProps> = (props: MHProps & RouteComponentProps)  => {

  const [activeTool, setActiveTool] = React.useState<React.FC<SiteActionProps> | undefined>(undefined);

  const generateMenuItem = (entry: SiteEntry, isTopLevel: boolean) => {
    if(isPage(entry)){
      const {id, title, path} = entry;
      return <li key={id}><NavLink id={id} exact={true} activeClassName="active" className={isTopLevel ? "nav-link" : "dropdown-item"} to={path}>{title} </NavLink></li>
    }else if(isGroup(entry)){
      const {id, title, children} = entry;
      return <DropdownMenuItem key={id} id={id} name={title} path="" onSelect={() => {}} >
                {children?.map(cEntry => generateMenuItem(cEntry, false))} 
              </DropdownMenuItem>;    
    }else{
      const {id, title, renderMethod} = entry;
      const renderedComponent = renderMethod({onDone: () => setActiveTool(undefined)});
      return <Link key={id} className="dropdown-item" to={"#"} onClick={() => setActiveTool(() => renderedComponent)}>{title}</Link>
      // return <Link key={id} className="dropdown-item" to={"#"} onClick={() => setPopupState({poppedComponent: renderMethod(), popped: true})}>{title}</Link>
    }
  }

return <>
    {activeTool}
    <Menu title={props.title}>
      {props.config.map((entry:SiteEntry) => generateMenuItem(entry, true))}               
    </Menu></>  
}

const DropdownMenuItem : React.FC<MIProp> = props => {
  const handleClick = (event : React.SyntheticEvent) => {
    event.preventDefault();
    props.onSelect(props.id);
  }

  return <li className="nav-item dropdown">
            <NavLink exact={true} to="derpa" onClick={handleClick} activeClassName="active" className="nav-link dropdown-toggle" data-toggle="dropdown">{props.name} <span className="caret"></span></NavLink>
            <div className="dropdown-menu" role="menu">
            { props.children }
            </div>
  </li>
}

const Menu : React.FC<MProps> = props => {
  return <div className="header clearfix">
          <h3 className="text-muted hidden-xs">{props.title}</h3>
          <nav className="col-xs-10 col-xs-offset-1">
            <ul className="d-lg-flex nav nav-pills float-right">
              {props.children}
            </ul>
          </nav>
        </div>;
}

// const MenuItem: React.FC<MIProp> = props => {

//     const handleClick = (event: React.SyntheticEvent) => {
//       props.onSelect(props.id);
//     }

//     return <li className="nav-item">
//       <NavLink exact={true} activeClassName="active" className="nav-link" to={props.path} onClick={(e) => handleClick(e)}>{props.name} </NavLink>
//       </li>;
//     }

export default withRouter(MenuHeader);