const dev = {
    graphql: {
        url: "http://localhost:8080/graphql",
        credentials: "include"
    },
    pdf: {
        url: "http://localhost:8080/wishlist-pdf"
    },
    upload: {
        url: "http://localhost:8080/upload"
    },
    files: {
        url: "http://localhost:8080/files/"
    }
};

const prod = {
    graphql: {
        url: "https://taghof.dk/graphql",
        credentials: "same-origin"
    },
    pdf: {
        url: "https://taghof.dk/wishlist-pdf"
    },
    upload: {
        url: "https://taghof.dk/upload"
    },
    files: {
        url: "https://taghof.dk/files/"
    }
};

const config = process.env.NODE_ENV === 'production'
    ? prod
    : dev;

export default {
    ...config
};