import * as React from "react";
import { GET_ALL_WISHES } from "../../graphql/GraphqlQueries";
import { useQuery } from "@apollo/react-hooks";
import config from "../../config";
import { WishList } from "./Wishlist";
import { Wish } from "../../graphql/GraphqlTypes";
import { LoadingProps, withLoading } from "../hocs/LoadingHOC";

export interface WishListData {
  user: string;
  wishes: Wish[];
}

export const graphqlEndpoint: string = config.graphql.url;
const pdfEndpoint: string = config.pdf.url;

let parseData = (input: Wish[]) => {
  let result = new Map<string, Wish[]>();
  input.forEach((wish: any) => {
    if (wish.user) {
      if (!result.has(wish.user.realFirstName)) {
        result.set(wish.user.realFirstName, []);
      }
      result.get(wish.user.realFirstName)!.push(wish);
    }
  });
  let data: WishListData[] = [];
  result.forEach((value, key, map) => {
    data.push({ user: key, wishes: value });
  });
  data.sort((a, b) => {
    if (a.user > b.user) {
      return 1;
    } else if (a.user < b.user) {
      return -1;
    } else {
      return 0;
    }
  });
  return data;
};

interface WishlistsProps extends LoadingProps {
  users?: String[];
}

const InternalWishLists: React.FC<WishlistsProps> = (props) => {
  const { loading, error, data, refetch } = useQuery(GET_ALL_WISHES);
  props.setLoading(loading);
  if (loading) return <div className="row marketing"></div>;
  if (error) return <div className="row marketing">Error! {error.message}</div>;
  return (
    <>
      <div className="row marketing">
        {parseData(data.getAllWishes)
          .filter(
            (wl) =>
              !props.users ||
              props.users.length === 0 ||
              props.users.includes(wl.user)
          )
          .map((wl) => (
            <WishList key={wl.user} updateCallback={refetch} data={wl} />
          ))}
      </div>
      <article className="col-lg-12 col-xs-12">
        <div className="dropdown pdfButton">
          <button
            className="btn btn-default dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            Hent Ønskesedler som pdf
            <span className="caret"></span>
          </button>
          <ul className="dropdown-menu pdfButtonContainer">
            {parseData(data.getAllWishes).map((wl) => (
              <li key={wl.user}>
                <a href={pdfEndpoint + "?id=" + wl.wishes[0].user!.id}>
                  {wl.user}
                </a>
              </li>
            ))}
            <li className="divider"></li>
            <li>
              <a href={pdfEndpoint}>Samlet</a>
            </li>
          </ul>
        </div>
      </article>
    </>
  );
};

export const WishLists = withLoading(InternalWishLists);

export default WishLists;
