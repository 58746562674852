import { WishInput, UpdateWishMutation, UpdateWishMutationVariables, WishState } from "../../graphql/GraphqlTypes";
import { removeProperty } from "../../utils";
import React from "react";
import { useMutation } from "react-apollo";
import { UPDATE_WISH } from "../../graphql/GraphqlQueries";

interface WishProp {
    data: WishInput,
    editable: Boolean,
    updateCallback: () => void;
}

export const WishComponent: React.FC<WishProp> = (props) => {
    props.data.user = removeProperty({ ...props.data.user, enabled: true, roles: null }, "__typename");
    props.data.priority = 1;

    const [editState, setEditState] = React.useState(false);
    const [wishState, setWishState] = React.useState<WishInput>(removeProperty({...props.data, state: WishState.Pending}, "__typename"));
    const [updateWishMutation] = useMutation<UpdateWishMutation, UpdateWishMutationVariables>(UPDATE_WISH);

    const hideWish = () => {
        setWishState({ ...wishState, state: WishState.Hidden });
        updateWishMutation({ variables: { wish: { ...wishState, state: WishState.Hidden } } }).then((res) => setEditState(false));
    }

    const updateWish = () => {
        updateWishMutation({ variables: { wish: wishState } }).then(res => setEditState(res.errors !== undefined)).catch(e => console.log(e));
    }

    let editButton = !editState && props.editable ?
        <div className="float-right">
            <button type="button" onClick={() => setEditState(true)} className="btn btn-light btn-sm editWish wishlistButton">
                Rediger
            </button>
        </div> : null;

    let editInputsRow = editState ?
        <tr className="editRow">
            <td className="nameInput wishCell"><input onChange={(e) => setWishState({ ...wishState, name: e.target.value })} className="form-control input-sm" type="text" placeholder="Ønske" value={"" + wishState.name} /></td>
            <td className="descriptionInput wishCell"><input onChange={(e) => setWishState({ ...wishState, description: e.target.value })} className="form-control input-sm" type="text" placeholder="Beskrivelse" value={"" + wishState.description} /></td>
            <td className="priceInput wishCell"><input onChange={(e) => setWishState({ ...wishState, price: e.target.value })} className="form-control input-sm" min="0" type="number" placeholder="Pris" value={"" + wishState.price} /></td>
        </tr> : null;

    let editButtonsRow = editState ?
        <tr className="editButtonsRow">
            <td className="linkInput wishCell" colSpan={1}>
                <input onChange={(e) => setWishState({ ...wishState, link: e.target.value })} className="form-control input-sm" type="text" placeholder="Link" value={"" + wishState.link} />
            </td>
            <td className="editButtonsCell" colSpan={2}>
                <div className="btn-group float-right">
                    <button onClick={() => hideWish()} className="btn btn-light btn-sm deleteWish" type="button">Slet</button>
                    <button onClick={() => setEditState(false)} className="btn btn-light btn-sm cancelUpdateWish" type="button">Fortryd</button>
                    <button onClick={() => updateWish()} className="btn btn-light btn-sm updateWish" type="button">Gem</button>
                </div>
            </td>
        </tr> : null;

    let displayRows = !editState ?
        <><tr className="displayRow" style={dottedBorderTop}>
            <td className="col-lg-5 col-xs-5 nameTd wishCell">{wishState.name}</td>
            <td className="col-lg-5 col-xs-5 descriptionTd wishCell">{wishState.description}</td>
            <td className="col-lg-2 col-xs-2 priceTd wishCell text-right">{wishState.price ? wishState.price + (wishState.price.endsWith("kr") ? "" : " kr") : ""}</td>
        </tr>
            <tr className="displayRow">
                <td style={zeroPxBorderTop} colSpan={2} className="col-lg-11 col-xs-11 linkTd linkCell">
                    <a className="no-padding-left-col col-lg-11 col-xs-11" style={{ paddingLeft: "0px" }} href={"" + wishState.link}>{wishState.link}</a>
                </td>
                <td style={zeroPxBorderTop}>
                    {editButton}
                </td>
            </tr></> : null;
    
    return <>
            {wishState.state === WishState.Pending ? 
                <>
                {displayRows}
                {editInputsRow}
                {editButtonsRow}
                </>
            : undefined }
            </>
    }

let dottedBorderTop = {
    borderTop: "1px dotted"
}


let zeroPxBorderTop = {
    borderTop: "0px"
}

