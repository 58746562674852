import { User, WishInput, AddWishMutation, AddWishMutationVariables, WishState, UserInput } from "../../graphql/GraphqlTypes";
import { useMutation } from "react-apollo";
import { ADD_WISH } from "../../graphql/GraphqlQueries";
import React from "react";

interface UserValueProps {
    user: User,
    addWishCallback: (wish: WishInput) => void
}

export const NewWishForm: React.FC<UserValueProps> = (props: UserValueProps) => {
    const nref = React.useRef<HTMLInputElement>(document.createElement("input"));
    const dref = React.useRef<HTMLInputElement>(document.createElement("input"));
    const pref = React.useRef<HTMLInputElement>(document.createElement("input"));
    const lref = React.useRef<HTMLInputElement>(document.createElement("input"));
    const [enabled, setEnabled] = React.useState<boolean>(false);
    const [addWishMutation, { loading }] = useMutation<AddWishMutation, AddWishMutationVariables>(ADD_WISH,
        {
            onCompleted: (data) => {
                resetNewWishState();
                props.addWishCallback(data.addWish! as WishInput);
            }
        });

    const validateWishInput = () => {
        let currentInput = getWishInput();
        if (currentInput.name != null && currentInput.name !== "") {
            setEnabled(true);
        } else {
            setEnabled(false);
        }
    }

    const resetNewWishState: () => void = () => {
        nref.current.value = "";
        dref.current.value = "";
        pref.current.value = "";
        lref.current.value = "";
    }

    const getWishInput: () => WishInput = () => {
        return {
            id: -1,
            priority: 1,
            link: lref.current.value,
            description: dref.current.value,
            name: nref.current.value,
            price: pref.current.value,
            numericalPrice: pref.current.value,
            state: WishState.Pending,
            updated: new Date(),
            user: { ...props.user, enabled: true, roles: null } as UserInput
        };
    }

    return <>
        <tr id="newWish" className="editRow">
            <td className="nameInput wishCell">{loading ? <div className="loadingOverlay">Gemmer nyt ønske...</div> : undefined}<input ref={nref} onChange={(e) => validateWishInput()} className="form-control input-sm" type="text" placeholder="Ønske" /></td>
            <td className="descriptionInput wishCell"><input ref={dref} onChange={(e) => validateWishInput()} className="form-control input-sm" type="text" placeholder="Beskrivelse" /></td>
            <td className="priceInput wishCell"><input ref={pref} onChange={(e) => validateWishInput()} className="form-control input-sm" min="0" type="number" placeholder="Pris" /></td>
        </tr>
        <tr id="newWish" className="editButtonsRow">
            <td colSpan={1} className="linkInput wishCell">
                <input ref={lref} onChange={(e) => validateWishInput()} className="form-control input-sm" type="text" placeholder="Link" />
                <input id="csrf" type="hidden" />
            </td>
            <td className="linkInput wishCell editButtonsCell" colSpan={2}>
                <div className="btn-group float-right">
                    <button className="btn btn-light btn-sm" type="reset">Fortryd</button>
                    <button disabled={!enabled} className="btn btn-light btn-sm addWish" onClick={() => { addWishMutation({ variables: { wish: getWishInput() } }) }} type="button">Gem</button>
                </div>
            </td>
        </tr>
    </>;
}
