import React, { useEffect } from "react";
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

export interface ModalProps {
	handleClose: () => void;
	handleSave?: () => void;
	visible: boolean;
	title?: string;
	className: string;
}

export const Modal: React.FC<ModalProps> = (props) =>  {
	const [visible, setVisible] = React.useState<boolean>(props.visible);
	useEffect(() => {
		setVisible(props.visible);
	  }, [props.visible]);
	 
	const close = () => {
		setVisible(false)
		props.handleClose();
	}
	const save = () => {
		setVisible(false)
		props.handleSave?.();
	}
	return 	<>{visible ? 
			<div className={visible ? "modal show" : "modal noshow"}>
				<ModalContainer className={props.className + " modal-main"}>
			        <Header><Title>{props.title}</Title>{props.handleSave && <CheckIcon onClick={()=>save()}/>}<CloseIcon onClick={() => close()}/></Header>
			        {props.children}
			    </ModalContainer>
			</div> : undefined}
			</>;
}

const Header = styled.div`
display: flex;
`;
const Title = styled.div`
flex: 1;
`;

const ModalContainer = styled.section`
	position:fixed;
	background: white;
	top:50%;
	left:50%;
	transform: translate(-50%,-50%);
	padding: 16px;

  }
`;
export default Modal;