import { WishListData } from "./WishLists";
import { useAuth } from "../../AuthContext";
import React from "react";
import { User, Wish } from "../../graphql/GraphqlTypes";
import { WishComponent } from "./WishComponent";
import { NewWishForm } from "./NewWishComponent";
import { LoadingProps, withLoading } from "../hocs/LoadingHOC";

interface WishListProps extends LoadingProps {
  data: WishListData;
  updateCallback: () => void;
}

const InternalWishList: React.FC<WishListProps> = (props) => {
  const { authState } = useAuth();
  const { user, wishes } = props.data;
  const [stateWishes, setStateWishes] = React.useState<Wish[]>(wishes);

  React.useEffect(() => {
    setStateWishes(props.data.wishes);
  }, [props.data.wishes]);

  const addWish = (wish: Wish) => {
    setStateWishes(stateWishes.concat([wish]));
  };

  return (
    <div className="article col-lg-12 col-xs-12">
      <table className="col-lg-12 col-xs-12 table fixedTable">
        <thead>
          <tr>
            <th className="col-lg-5 col-xs-5" style={{ borderTop: "0px" }}>
              {user}s ønsker
            </th>
            <th className="col-lg-5 col-xs-5" style={{ borderTop: "0px" }}>
              Beskrivelse
            </th>
            <th
              className="col-lg-2 col-xs-2 text-right"
              style={{ borderTop: "0px" }}
            >
              Pris
            </th>
          </tr>
        </thead>
        <tbody>
          {stateWishes.map((w) => (
            <WishComponent
              key={w.id}
              updateCallback={props.updateCallback}
              data={w}
              editable={authState.state === "authenticated"}
            />
          ))}
          {authState.state === "authenticated" ? (
            <NewWishForm
              addWishCallback={addWish}
              user={stateWishes[0].user as User}
            />
          ) : null}
        </tbody>
      </table>
    </div>
  );
};

export const WishList = withLoading(InternalWishList);
