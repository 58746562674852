import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import Modal from "../utils/Modal";
import {
  BlogPost,
  UpdateBlogPostMutationVariables,
  UpdateBlogPostMutation,
  DeleteBlogPostMutation,
  DeleteBlogPostMutationVariables,
  AddBlogPostMutation,
  AddBlogPostMutationVariables,
} from "../../graphql/GraphqlTypes";
import MEDitor, { ICommand, TextAreaTextApi, TextState, commands } from "@uiw/react-md-editor";
import { useMutation, useQuery } from "react-apollo";
import {
  UPDATE_BLOGPOST,
  DELETE_BLOGPOST,
  ADD_BLOGPOST,
  GET_ALL_BLOGPOSTS,
  GET_PHOTOS,
} from "../../graphql/GraphqlQueries";
import { removeProperties } from "../../utils";
import { useAuth } from "../../AuthContext";
import styled from "styled-components";
import { SiteActionProps } from "../../Site";
import { withLoading, LoadingProps } from "../hocs/LoadingHOC";
import { GroupOptions } from "@uiw/react-md-editor/lib/commands/group";
import { Button } from "semantic-ui-react";
import config from "../../config";

export interface BlogPostProps {
  post: BlogPost;
}

const InternalBlogPostComponent: React.FC<BlogPostProps & LoadingProps> = (
  props
) => {
  const { authState } = useAuth();
  const [updateBlogpostMutation] = useMutation<
    UpdateBlogPostMutation,
    UpdateBlogPostMutationVariables
  >(UPDATE_BLOGPOST);
  const [deleteBlogpostMutation, deleteState] = useMutation<
    DeleteBlogPostMutation,
    DeleteBlogPostMutationVariables
  >(DELETE_BLOGPOST);
  const [showEditor, setShowEditor] = React.useState<boolean>(false);
  const post = props.post;
  const [currentText, setCurrentText] = React.useState<string>(post.text || "");
  const handleDelete: () => void = () => {
    deleteBlogpostMutation({ variables: { blogPostId: post.id } });
  };
  
  const handlePublish: () => void = () => {
    post.published = !post.published;
    updateBlogpostMutation({
      variables: {
        blogPost: removeProperties({ ...post }, [
          "publishedDate",
          "__typename",
        ]),
      },
    });
  };

  const handleEdit: (text: string) => void = (text: string) => {
    updateBlogpostMutation({
      variables: {
        blogPost: removeProperties({ ...post }, [
          "publishedDate",
          "__typename",
        ]),
      },
    }).then(() => {
      post.text = currentText;
      setShowEditor(false);
    });
  };

  const handleCancel: () => void = () => {
    setCurrentText(post.text || "");
    setShowEditor(false);
  };

  const buttons =
    authState.state === "authenticated" ? (
      <div className="btn-group">
        <button className="btn btn-default" onClick={() => handleDelete()}>
          Delete
        </button>
        <button className="btn btn-default" onClick={() => setShowEditor(true)}>
          Edit
        </button>
        <button className="btn btn-default" onClick={() => handlePublish()}>
          {post.published ? "Unpublish" : "Publish"}
        </button>
      </div>
    ) : undefined;
  const dateFormat = require("dateformat");
  const date = post.publishedDate && new Date(Date.parse(post.publishedDate));
  const d = dateFormat(date, "yyyy-mm-dd, hh:MM");
  if (!deleteState.called) {
    return (
      <div className="BlogPost">
        {buttons}
        <div className="headerdate">{d}</div>
        {showEditor ? (
          <SizedModal
            className="editModal"
            visible={showEditor}
            handleSave={() => handleEdit(currentText)}
            handleClose={() => handleCancel()}
          >
            <StyledMEDitor
              value={currentText}
              onChange={(v) => setCurrentText(v || "")}
              visiableDragbar={false}
              commands={[
                ...commands.getCommands().filter(c => c.name !== "image"),
                commands.group([], addPhotoCommand())]}
            />
          </SizedModal>
        ) : (
              <MEDitor.Markdown source={currentText} />
        )}
      </div>
    );
  } else if (deleteState.loading) {
    return <div>Deleting post...</div>;
  } else {
    return <></>;
  }
};

export const addPhotoCommand: () => GroupOptions = () => ({
  groupName: "Add Photo",
  name: "Add Photo",
  buttonProps: { "aria-label": "Add Photo" },
  icon: commands.image.icon,
  children: (props) => <AddPhotoCommand {...props} />,
  execute: (state: TextState, api: TextAreaTextApi) => {}
});


function AddPhotoCommand(props: cProps) {
  const { close, getState = () => false, textApi } = props;
  const {authState: {authToken}} = useAuth();
  const { loading, error, refetch, data } = useQuery(GET_PHOTOS, {fetchPolicy: "network-only"});
  const inputRef = React.createRef<HTMLInputElement>();
  const imgCommands = data?.getPhotos ?? [];
  const fileChange = (c: ChangeEvent<HTMLInputElement>) => {
    const xhr = new XMLHttpRequest();
    xhr.timeout = 20000; // 2 seconds
    xhr.upload.addEventListener("loadstart", (event) => {});
    xhr.upload.addEventListener("progress", (event) => {});
    // xhr.upload.addEventListener("loadend", (event) => {refetch()});
    // Build the payload
    const fileData = new FormData();
    if(c.target.files){
      fileData.append("file", c.target?.files?.[0]);
    }
    xhr.open("POST", config.upload.url, true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + authToken);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        const status = xhr.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          // The request has been completed successfully
          console.log(xhr.responseText);
          refetch();
        } else {
          // Oh no! There has been an error with the request!
        }
      };
    };
    xhr.send(fileData);

  };

  let desc = "";
  try {
    desc = getState() !== false && (getState() as TextState).selectedText ? 
      `\n*${(getState() as TextState).selectedText}*` : "";
  } catch(e){}

  return (
    <div style={{ display: "flex", flexDirection: "column", width: 240, padding: 10 }}>
      <input hidden ref={inputRef} type="file" onChange={fileChange} />
      <Button style={{marginBottom: "16px"}} onClick={() => inputRef.current?.click()}>Upload Photo</Button>
      {imgCommands.map((img:{filename: string, url: string}) => <div key={img.filename} style={{display: "flex", gap: "12px"}}>
        <img alt="herp derp" height="12px" width="12px" src={img.url}/>
        <label onClick={() => { textApi?.replaceSelection(`![](${img.url})${desc}`); close();}} style={{width: "180px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{img.filename}</label></div>)}    
    </div>
  );
}

type cProps = {
  close: () => void;
  execute: () => void;
  getState?: (() => false | TextState) | undefined;
  textApi?: TextAreaTextApi | undefined;
}

const SizedModal = styled(Modal)`
  height: 80vh;
  display: flex;
  flex-direction: column;
`

export const StyledMEDitor = styled(MEDitor)`
  flex: 1;
  display: flex;
  flex-direction: column;
  & .w-md-editor-content {
    flex: 1;
  }
  & .w-md-editor-text {
    min-height: unset;
  }
  & .w-md-editor-toolbar-child.active {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  width: 80vw;
`;

export const BlogPostComponent = withLoading(InternalBlogPostComponent);

export const InternalAddBlogPostComponent: React.FC<
  SiteActionProps & LoadingProps
> = (props) => {
  const [text, setText] = React.useState<string>("Enter your words of wisdom");
  const [save] = useMutation<AddBlogPostMutation, AddBlogPostMutationVariables>(
    ADD_BLOGPOST,
    { refetchQueries: () => [{ query: GET_ALL_BLOGPOSTS }] }
  );
  const handleCreate = (text: string) => {
    save({ variables: { blogPost: text } })
      .then((res) => console.log("saving shit"))
      .catch((e) => console.log(e));
    props.onDone();
  };
  const handleCancel = () => {
    setText("");
    props.onDone();
  };

  return (
    <Modal
      className="editModal"
      visible={true}
      handleSave={() => handleCreate(text)}
      handleClose={() => handleCancel()}
    >
      <StyledMEDitor
        height={600}
        value={text}
        visiableDragbar={false}
        onChange={(v) => setText(v || "")}
        commands={[
          ...commands.getCommands().filter(c => c.name !== "image"),
          commands.group([], addPhotoCommand())]}
      />
    </Modal>
  );
};

export const AddBlogPostComponent = withLoading(InternalAddBlogPostComponent);
