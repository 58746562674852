import React from "react";
import "./App.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "semantic-ui-css/semantic.css";
import { AuthProvider } from "./AuthContext";

import { ApolloProvider } from "./ApolloContext";
import { Site } from "./Site";
import { PopupProvider } from "./components/popup/PopupContext";
import { ModalProvider } from "styled-react-modal";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ApolloProvider>
        <PopupProvider>
          <ModalProvider>
            <Site />
          </ModalProvider>
        </PopupProvider>
      </ApolloProvider>
    </AuthProvider>
  );
};

export default App;
