import { useAuth } from "../../AuthContext";
import React from "react";
import MultiSelect from "react-multi-select-component";
import styled from "styled-components";
import Modal from "../utils/Modal";
import { SiteActionProps } from "../../Site";
import { Button } from "@material-ui/core";
import { useQuery, useLazyQuery } from "react-apollo";
import {
  GET_RECIPIENTS,
  GET_WISHLIST_OWNERS,
  SEND_WISHES,
} from "../../graphql/GraphqlQueries";
import { User } from "../../graphql/GraphqlTypes";

interface DistributerProps extends SiteActionProps {}

interface Contact {
  label: string;
  value: string;
}

interface WishlistOwner {
  label: string;
  value: string;
}

const Wrapper = styled.div`
  width: 300px;
`;

const SendButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
`;

const InputWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const WishlistDistributer: React.FC<DistributerProps> = (
  props: DistributerProps
) => {
  const { authState } = useAuth();
  const parseRecipients = () => {
    if (recipients.loading) {
      return [];
    }
    const recObjs = recipients.data.getRecipients;
    return Object.keys(recObjs).map((key) => {
      return { label: String(key), value: recObjs[key] } as Contact;
    });
  };
  const parseOwners = () => {
    const ownerObjs = owners.loading
      ? ([] as User[])
      : (owners.data.getOwners as User[]);
    const ownerResult = ownerObjs.map((user: User) => {
      return {
        label: user.realFirstName,
        value: "" + user.id,
      } as WishlistOwner;
    });
    return ownerResult;
  };
  const [selectedReceivers, setSelectedReceivers] = React.useState<Contact[]>(
    []
  );
  const [selectedOwners, setSelectedOwners] = React.useState<WishlistOwner[]>(
    []
  );
  const recipients = useQuery(GET_RECIPIENTS);
  const owners = useQuery(GET_WISHLIST_OWNERS);
  console.log(selectedOwners.map((o) => o.value));
  console.log(selectedReceivers.map((r) => r.value));
  const [sendWishes, { error }] = useLazyQuery(SEND_WISHES, {
    variables: {
      users: selectedOwners.map((o) => o.value),
      recipients: selectedReceivers.map((r) => r.value),
    },
    onCompleted: () => props.onDone(),
  });
  let contacts = parseRecipients();
  let listOwners = parseOwners();

  const component =
    authState.state !== "authenticated" ? (
      <div>You must always be logged in to use this feature</div>
    ) : (
      <>
        <h4>Send Ønskesedler</h4>
        <InputWrapper>
          <label>Modtagere: </label>{" "}
          <MultiSelect
            options={contacts}
            value={selectedReceivers}
            onChange={setSelectedReceivers}
            labelledBy={"Select"}
          />{" "}
        </InputWrapper>
        <InputWrapper>
          <label>Ønskesedler: </label>{" "}
          <MultiSelect
            options={listOwners}
            value={selectedOwners}
            onChange={setSelectedOwners}
            labelledBy={"Select"}
          />{" "}
        </InputWrapper>
        <InputWrapper>
          <SendButton
            disabled={
              selectedReceivers.length === 0 || selectedOwners.length === 0
            }
            onClick={() => sendWishes()}
            variant="contained"
            color="primary"
          >
            Send
          </SendButton>
        </InputWrapper>
        {error && <div>Der skete en fejl, ønskesedlerne er ikke sendt :(</div>}
      </>
    );

  return (
    <Modal handleClose={() => props.onDone()} visible={true} className={"derp"}>
      <Wrapper> {component} </Wrapper>
    </Modal>
  );
};

export default WishlistDistributer;
