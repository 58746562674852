export const formatTime = (seconds: number) =>
  new Date(seconds * 1000).toLocaleTimeString('en-GB', {
    timeZone:'Etc/UTC',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  }).replace(/^(?:00:)?0?/, '');
  export const formatDistance = (distance: number) =>  Math.round((distance + Number.EPSILON) * 100) / 100 + " km";
  export const formatSpeed = (speed: number) =>  Math.round((speed + Number.EPSILON) * 100) / 100 + " km/t";
