import { useApolloClient } from "@apollo/react-hooks";
import React, { FC, useCallback, useContext, useReducer, useRef } from "react";
import { LOGIN_QUERY, REFRESH_TOKEN_QUERY } from "./graphql/GraphqlQueries";
import {
  AuthReply,
  LoginQuery,
  LoginQueryVariables,
  RefreshQuery,
} from "./graphql/GraphqlTypes";
import ApolloClient from "apollo-client/ApolloClient";

export interface AuthState {
  state: "authenticated" | "unAuthenticated" | "expired";
  user?: UserValue;
  authToken?: string;
  authTimestamp?: string;
  authError?: string;
}

export interface UserValue {
  id: number;
  firstName: string;
  lastName: string;
  login: string;
  roles: RoleValue[];
}

export interface RoleValue {
  name: string;
}

export interface AuthContext {
  authState: AuthState;
  authDispatch: React.Dispatch<AuthAction>;
}

let defaultAuthState: AuthState = { state: "unAuthenticated" };
let defaultContext: AuthContext = {
  authState: defaultAuthState,
  authDispatch: () => {},
};

export const AuthContext = React.createContext(defaultContext);

export const useAuth = (): AuthContext & {
  login: (userName: string | undefined, password: string | undefined) => void;
  logout: () => void;
} => {
  const { authState, authDispatch } = useContext(AuthContext);
  const client = useApolloClient();
  const refreshRef = useRef<NodeJS.Timeout>();

  const login = useCallback(
    (userName: string | undefined, password: string | undefined) => {
      if (userName && password) {
        client
          .query<LoginQuery, LoginQueryVariables>({
            query: LOGIN_QUERY,
            fetchPolicy: "network-only",
            variables: {
              username: userName,
              password: password,
            },
          })
          .then((result) => {
            if (result.data.login?.success) {
              refreshRef.current && clearInterval(refreshRef.current);
              refreshRef.current = startRefreshCycle(900000 - 10000, client, authDispatch);
              authDispatch({ type: "loginSuccess", result: result.data.login });
            }else{
              authDispatch({ type: "loginFailure", error: result.data.login?.resultString ?? "Error logging in"});

            }
          })
          .catch((error) => authDispatch({ type: "loginFailure", error }));
      }
    },
    [client, authDispatch]
  );

  const logout = useCallback(() => {
    authDispatch({ type: "logoutSuccess" });
    client.cache.reset();
    refreshRef.current && clearInterval(refreshRef.current);
  }, [authDispatch, client]);

  return { authState, authDispatch, login, logout };
};

export type AuthAction =
  | { type: "loginSuccess"; result: AuthReply }
  | { type: "logoutSuccess" }
  | { type: "loginFailure"; error: string }
  | { type: "refreshSuccess"; result: AuthReply }
  | { type: "refreshFailure"; error: string; askForReauth: boolean };

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "loginSuccess":
      const { login, token: authToken, firstName, lastName } = action.result;
      localStorage.setItem("authState", "authenticated");
      return {
        state: "authenticated",
        authToken: authToken ?? undefined,
        authError: undefined,
        user: {
          id: -1,
          login: login ?? "",
          firstName: firstName ?? "",
          lastName: lastName ?? "",
          roles: [],
        },
      };
    case "refreshSuccess":
      const res = action.result;
      localStorage.setItem("authState", "authenticated");
      return {
        state: "authenticated",
        authToken: authToken ?? undefined,
        user: {
          id: -1,
          login: res.login ?? "",
          firstName: res.firstName ?? "",
          lastName: res.lastName ?? "",
          roles: [],
        },
      };
    case "logoutSuccess":
      localStorage.setItem("authState", "unAuthenticated");
      return { state: "unAuthenticated" };
    case "loginFailure":
      localStorage.setItem("authState", "unAuthenticated");
      return { state: "unAuthenticated", authError: action.error };
    case "refreshFailure":
      localStorage.setItem(
        "authState",
        action.askForReauth ? "expired" : "unAuthenticated"
      );
      return { state: action.askForReauth ? "expired" : "unAuthenticated" };
  }
}

export const AuthProvider: FC = ({ children }) => {
  const [authState, authDispatch] = useReducer(authReducer, {
    state: "unAuthenticated",
  });

  // React.useEffect(() => {
  //   if (authState.authenticated) {
  //     localStorage.setItem(refreshTokenStorageKey, authState.refreshToken!);
  //   } else {
  //     localStorage.removeItem(refreshTokenStorageKey);
  //   }
  // }, [authState]);

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const startRefreshCycle = (delay: number, client: ApolloClient<object>, authDispatch: React.Dispatch<AuthAction>
) => {
    const refreshInterval = setInterval(() => {
      client
        .query<RefreshQuery>({
          query: REFRESH_TOKEN_QUERY,
          fetchPolicy: "network-only",
        })
        .then((response) => {
          if (response.data.refresh?.success) {
            authDispatch({
              type: "loginSuccess",
              result: response.data.refresh,
            });
          } else {
            authDispatch({
              type: "refreshFailure",
              error:
                response.errors?.map((e) => e.message).join(" | ") ??
                "Not able to refresh token",
              askForReauth: true,
            });
            clearInterval(refreshInterval);
          }
        })
        .catch((error) => {
          authDispatch({ type: "refreshFailure", error, askForReauth: true });
          clearInterval(refreshInterval);
        });
    }, delay);
    return refreshInterval;
  };


export const _ = "";
