import React, { FC, useCallback, useEffect, useState } from "react";
import { useQuery } from "react-apollo";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMeta,
  CardDescription,
  Label,
} from "semantic-ui-react";
import styled from "styled-components";
import Modal from "styled-react-modal";
import config from "../../config";
import { GET_LATEST_WORKOUTS } from "../../graphql/GraphqlQueries";
import {
  QueryGetWorkoutsArgs,
  SportType,
  Workout,
  WorkoutsQuery,
} from "../../graphql/GraphqlTypes";
import {
  formatDistance,
  formatSpeed,
  formatTime,
} from "../utils/UtilFunctions";
import { RouteMap } from "./RouteMap";

interface RunningCardsProps {}

export const RunningCards: FC<RunningCardsProps> = (props) => {
  const { loading, error, data } = useQuery<
    WorkoutsQuery,
    QueryGetWorkoutsArgs
  >(GET_LATEST_WORKOUTS, { variables: { count: 11 } });

  if (loading) {
    return null;
  }
  if (error) {
    return <div>{error}</div>;
  }
  console.log(data);
  return (
    <CardGrid>
      {data?.getWorkouts?.filter(Boolean).map((w, index) => (
        <StyledCard key={w?.externalId + index}>
          <SlimCardContent>
            <SlimCardHeader>
              <SportsIcon className="material-icons">directions_run</SportsIcon>
              {w!.sport === SportType.Running ? "Run" : "Ride"}
            </SlimCardHeader>
            <CardMeta>
              {new Intl.DateTimeFormat("da-DK", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(new Date(w!.datetime))}
            </CardMeta>
          </SlimCardContent>
          <CardContent>
            <CardDescription>
              <FSLabel>Tid:</FSLabel>
              {formatTime(w!.duration)}
              <br />
              <FSLabel>Distance: </FSLabel>
              {formatDistance(w!.distance)}
              <br />
              <FSLabel>Hastighed: </FSLabel>
              {formatSpeed(w!.avgSpeed)}
              <br />
              <FSLabel>Puls</FSLabel>
              {w!.avgHR + " bpm"}
              <br />
            </CardDescription>
          </CardContent>
          <CardContent extra={true}>
            <CardMeta>
              <DetailsButton label="Details" workoutId={w?.id} />
            </CardMeta>
          </CardContent>
        </StyledCard>
      ))}
    </CardGrid>
  );
};

const StyledModal = Modal.styled`
  width: 80vw;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

interface DetailsProps {
  label: string;
  workoutId: string;
}

const DetailsButton: React.FC<DetailsProps> = ({ label, workoutId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [parsedRoute, setParsedRoute] = useState<GeoJSON.GeoJsonObject>();

  useEffect(() => {
    if (!parsedRoute && isOpen) {
      fetch(config.graphql.url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: "{getRouteForWorkout(id : " + workoutId + ")}",
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.data && res.data.getRouteForWorkout) {
            let parsed = JSON.parse(res.data.getRouteForWorkout.route);
            setParsedRoute(parsed);
          }
        })
        .catch((Error) => console.log(Error));
    }
  }, [parsedRoute, isOpen, workoutId]);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <div>
      <button onClick={toggleModal}>Click me</button>
      <StyledModal
        isOpen={isOpen}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
      >
        {parsedRoute && <RouteMap geoJson={parsedRoute} routeID={workoutId} />}
        <button onClick={toggleModal}>Close me</button>
      </StyledModal>
    </div>
  );
};
const SportsIcon = styled.i`
  float: left;
`;

const FSLabel = styled(Label)`
  width: 80px;
  &.ui.label {
    margin-bottom: 4px;
    margin-left: 0px;
  }
`;

const SlimCardContent = styled(CardContent)``;
const SlimCardHeader = styled(CardHeader)`
  padding-bottom: 0px;
  border-bottom: 0px;
  margin-bottom: 4px;
`;

const CardGrid = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 16px;
  width: 100%;
`;

const StyledCard = styled(Card)`
  &.ui.card {
    height: 280px;
    margin: 0em 0em;

    &:first-child {
      margin: 0em 0em;
    }
  }
  & ${SlimCardContent} {
    flex-grow: 0 !important;
    & ${SlimCardHeader} {
    }
  }
`;
