import React from 'react'
import { GET_ALL_WORKOUTS } from "../../graphql/GraphqlQueries"
import ApolloClient from 'apollo-boost';
import './Table.css';
import config from '../../config';
import { LoadingProps, withLoading } from '../hocs/LoadingHOC';

const client = new ApolloClient({
    uri: config.graphql.url
});

interface TableProps extends LoadingProps {
    mapCallback: (selectedWorkoutId: string) => void;
}

let toggleDetails = (event: any, table: any) => {
    var tr = $(event.currentTarget).closest('tr');
    var row = table.row(tr);
    var data = row.data();
    if (row.child.isShown()) {
        data.detailsShown = false;
        // This row is already open - close it
        row.data(data);
        row.child.hide();
        tr.removeClass('shown');
        row.invalidate();
    }
    else {
        data.detailsShown = true;
        // Open this row
        row.child(formatDetails(row.data())).show();
        row.data(data);
        tr.addClass('shown');
        row.invalidate();
    }
};

let columns = [
    {
        "class": "details-control",
        "orderable": false,
        "data": "detailsShown",
        "defaultContent": "",
        "render": (data: any, type: any, row: any, meta: any) => {
            if (data === true) {
                return '<i class="material-icons">expand_less</i>';
            } else {
                return '<i class="material-icons">expand_more</i>';
            }
        }
    },
    {
        "class": "type",
        "data": "sport",
        "defaultContent": "",
        "render": function (data: any, type: any, row: any, meta: any) {
            if (data === 'CYCLING') {
                return '<i class="material-icons">directions_bike</i>';
            } else if (data === 'RUNNING') {
                return '<i class="material-icons">directions_run</i>';
            } else if (data === 'WALKING') {
                return '<i class="material-icons">directions_walk</i>';
            } else if (data === 'STRENGTH') {
                return '<i class="material-icons">fitness_center</i>';
            } else {
                console.log(data);
            }
        }
    },
    {
        "label": "Tidspunkt",
        "data": "datetime",
        "type": "date",
        "dateFormat": "yy-mm-dd"
    },
    { data: "distance" },
    { data: "duration" },
    { data: "avgSpeed" },
    { data: "calories" }
];

let formatDetails = (d: any) => {
    // `d` is the original data object for the row
    return '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
        '<tr>' +
        '<td style="font-weight : bold; font-size : 10px;">Max hastighed:</td>' +
        '<td style="font-size : 10px;">' + d.maxSpeed + '</td>' +
        '<td style="font-weight : bold; font-size : 10px;">Max puls:</td>' +
        '<td style="font-size : 10px;">' + d.maxHR + '</td>' +
        '<td style="font-weight : bold; font-size : 10px;">Max højde:</td>' +
        '<td style="font-size : 10px;">' + d.altitudeMax + '</td>' +
        '<td style="font-weight : bold; font-size : 10px;">Højdemeter op:</td>' +
        '<td style="font-size : 10px;">' + d.elevationAsc + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="font-weight : bold; font-size : 10px;">Væskeforbrug:</td>' +
        '<td style="font-size : 10px;">' + d.hydration + '</td>' +
        '<td style="font-weight : bold; font-size : 10px;">Gns. puls:</td>' +
        '<td style="font-size : 10px;">' + d.avgHR + '</td>' +
        '<td style="font-weight : bold; font-size : 10px;">Min højde:</td>' +
        '<td style="font-size : 10px;">' + d.altitudeMin + '</td>' +
        '<td style="font-weight : bold; font-size : 10px;">Højdemeter ned:</td>' +
        '<td style="font-size : 10px;">' + d.elevationDesc + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td style="font-weight : bold; font-size : 10px;">Vejret:</td>' +
        '<td colspan="3" style="font-size : 10px;">' + d.weather + '</td>' +
        '<td></td>' +
        '<td></td>' +
        '<td></td>' +
        '<td></td>' +
        '</tr>' +
        '</table>';
}

let showMap = (type: string, index: number, table: any, callback: (id: string) => void) => {
    var rowData = table.row(index).data();
    callback(rowData.id);
}

const InternalTable: React.FC<TableProps> = (props: TableProps) => {

    const tableRef = React.createRef<HTMLTableElement>();
    const { mapCallback } = props;
    React.useEffect(() => {

        const $ = require('jquery');
        $.DataTable = require('datatables.net');
        require('datatables.net-select');

        const table = $(tableRef.current).DataTable({
            "createdRow": function (row: any, data: any, dataIndex: any) {
                $(row).attr('detailsShown', false);
            },
            "ajax": function (data: any, callback: (d: JSON) => void, settings: any) {
                console.log("Fetching all workouts");
                props.setLoading(true)
                client.query({ "query": GET_ALL_WORKOUTS, fetchPolicy: "network-only", fetchResults: true }).then((v: any) => { callback(v.data.getAllWorkouts); props.setLoading(false) }).catch(e => { console.log(e); props.setLoading(false); });
            },
            "sAjaxDataProp": "",
            "order": [[2, "desc"]],
            "bAutoWidth": false,
            "dom": 'l<"clear"><"#buttonPlaceholder">frtip',
            "select": { "style": 'single' },
            "columns": columns
        });

        table.on('select', function (e: any, table: any, type: any, indexes: any) {
            showMap(type, indexes[0], table, mapCallback);
        }).on('deselect', function (e: any, table: any, type: any, indexes: any) {
            mapCallback("");
        }).on('draw.dt', function () {
            table.row(':eq(0)', { page: 'current' }).select();
        });

        $('#runningTable tbody').on('click', 'td.details-control', (event: any) => toggleDetails(event, table));

        // client.query({
        //     query: SYNC_LATEST_WORKOUTS
        // }).then(res => table.ajax.reload()).catch(error => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div>
        <table id="runningTable" className="display" ref={tableRef}>
            <thead>
                <tr><th></th><th></th><th>Tidspunkt</th><th>Distance</th><th>Tid</th><th>Hastighed</th><th>Kalorier</th></tr>
            </thead>
        </table>
    </div>;
}
export const RunningTable = withLoading(InternalTable);
