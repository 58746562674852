import * as React from "react";
import { useQuery } from "react-apollo";
import { GET_STATS } from "../../graphql/GraphqlQueries";
import { StatsQuery, StatsQueryVariables } from "../../graphql/GraphqlTypes";
import { withLoading, LoadingProps } from "../hocs/LoadingHOC";

const InternalHomePage: React.FC<LoadingProps> = (props) => {

    interface WorkoutSummary {
        hoursAgo: number,
        type: string,
        distance: string,
        duration: string,
        pace: string
    }

    const parseWorkoutType = (type: string) => {
        switch (type) {
            case "RUNNING":
                return "løbetur";
            case "CYCLING":
                return "cykeltur";
            case "STRENGTH":
                return "omgang styrketræning";
            default:
                return "ikke nærmere specificeret workout"
        }
    }

    const parseDuration = (duration: string) => {
        let d = new Date(0);
        let durNum = Number.parseInt(duration);
        d.setSeconds(durNum);
        let days = durNum > 86400 ? (durNum / 86400) + "d" : ""
        return days + d.toISOString().substr(11, 8);
    }

    const { loading, error, data } = useQuery<StatsQuery, StatsQueryVariables>(GET_STATS);
    
    React.useEffect(() => {
        props.setLoading(loading);

    },[loading, error, data, props]);
    
    let content = <div></div>;

    if (error) {
        content = <div>error loading stats</div>;
    }

    if (loading) {
        content = <div>loading stats</div>;
    }

    if (data != null) {
        const wishListStats = data.wishListStats;
        const workoutStats = data.workoutStats;
        const lastWorkout = workoutStats ? workoutStats.lastRun : null;
        
        const totalWishesNum: number = wishListStats ? wishListStats.numberOfWishes : 0;
        const lastUpdate: string = new Date(wishListStats ? wishListStats.lastUpdate : 0).toLocaleString("da-DK", { day: "numeric", month: "short", year: "numeric", hour: "numeric", minute: "2-digit" });
        const totalDistance: string = (workoutStats ? workoutStats.totalDistance.toFixed(2) : 0) + " km";
        const totalDistanceRunning: string = (workoutStats ? workoutStats.totalDistanceRunning.toFixed(2) : 0) + " km";
        const totalDistanceCycling: string = (workoutStats ? workoutStats.totalDistanceCycling.toFixed(2) : 0) + " km";
        const totalTimeSpend: string = (workoutStats && workoutStats.totalTimeSpendString ? workoutStats.totalTimeSpendString : "0");
        const totalTimeSpendRunning: string = (workoutStats && workoutStats.totalTimeSpendRunningString ? workoutStats.totalTimeSpendRunningString : "0");
        const totalTimeSpendCycling: string = (workoutStats && workoutStats.totalTimeSpendCyclingString ? workoutStats.totalTimeSpendCyclingString : "0");
        const lastWorkoutValues: WorkoutSummary = {
            hoursAgo: lastWorkout ? Math.floor((new Date().getTime() - new Date(lastWorkout.datetime).getTime()) / 36000000) : 0,
            type: parseWorkoutType(lastWorkout && lastWorkout.sport ? lastWorkout.sport : "ANY"),
            distance: (lastWorkout ? lastWorkout.distance.toFixed(2) : 0) + " km",
            duration: parseDuration(lastWorkout ? lastWorkout.duration + "" : "0"),
            pace: (lastWorkout ? lastWorkout.avgSpeed.toFixed(2) : 0) + " km/t"
        };

        content = <>
            <div className="row marketing">
                <div className="col-lg-6">
                <h4>Ønskesedlerne</h4>
                    <p>Er sidst opdateret {lastUpdate} og indeholder ialt {totalWishesNum} ønsker.</p>

                    <h4>Sidste Træning</h4>
                    <p>Var en {lastWorkoutValues.type} for {lastWorkoutValues.hoursAgo} timer siden,
                        {" " + lastWorkoutValues.distance} blev tilbagelagt i tiden {lastWorkoutValues.duration}.
                        Gennemsnitshastigheden var {lastWorkoutValues.pace}.
                    </p>
                    <h4>Speciale</h4>
                    <p>Morten har engang for længe siden skrevet speciale om flyvende robotter, hvis det har nogen interesse er <a href="http://taghof.github.io/Navigation-for-Robots-with-WIFI-and-CV/">projektet beskrevet her</a>.</p>
                </div>

                <div className="col-lg-6">
                    <h4>Statistik</h4>
                    <p>Morten har indtil videre tracket {totalDistance}, og det har ialt taget {totalTimeSpend}.
                    Tid og distance er fordelt således: løb -	{totalDistanceRunning} på {totalTimeSpendRunning},
                    cykling - {totalDistanceCycling} på {totalTimeSpendCycling}.</p>

                    <h4>Billeder</h4>
                    <p>Vi har delt en håndfuld billeder af os og Irma, dem kan i se <a href="dr.dk">her</a>.</p>
                </div>
            </div>
        </>;
    }

    return <>
        <div className="jumbotron">
            <h1>Velkommen</h1>
            <p className="lead">
                Hvis du leder efter Irmas, Karens, Anes, Mariannes eller Mortens ønskeseddel
                er du kommet til det rigtige sted. Morten bruger også siden til at holde styr på sine løbe- og cykelture.
            </p>
        </div>
        {content}
    </>;
}

export default withLoading(InternalHomePage);
