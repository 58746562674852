import React from "react";
import styled from "styled-components";
import { Subtract } from 'utility-types';

export interface LoadingProps {
    setLoading: (b: boolean) => void
}

export const withLoading = <P extends LoadingProps>(Component: React.ComponentType<P>): 
        React.FC<Subtract<P, LoadingProps>> => (props) => {
    const [loading, setLoading] = React.useState(false);
    return <>{loading && <Spinner />}<Component {...props as P} setLoading={setLoading} /></>;
  }


const Spinner = () => (
  <StyledSpinner viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="2"
    />
  </StyledSpinner>
);

const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: #5652bf;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;