import * as React from "react";
import { GET_ALL_BLOGPOSTS } from "../../graphql/GraphqlQueries";
import { useQuery } from "@apollo/react-hooks";
import { BlogPost } from "../../graphql/GraphqlTypes";
import { BlogPostComponent } from "./BlogPostComponent";
import { useAuth } from "../../AuthContext";
import { withLoading, LoadingProps } from "../hocs/LoadingHOC";

interface BlogProps {}

const InternalBlog: React.FC<BlogProps & LoadingProps> = (props) => {
  const { authState } = useAuth();

  const { loading, error, data } = useQuery(GET_ALL_BLOGPOSTS);
  props.setLoading(loading);
  if (error) return <div className="row marketing">Error! {error.message}</div>;

  var rows = data
    ? data.getAllBlogPosts
        .filter(
          (p: BlogPost) => p.published || authState.state === "authenticated"
        )
        .map((post: BlogPost) => (
          <BlogPostComponent key={post.id} post={post} />
        ))
    : undefined;

  return (
    <>
      
      <div className="blogContainer">{rows}</div>
    </>
  );
};

export const Blog = withLoading(InternalBlog);

export default Blog;
